import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '150%', // 16:9
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  cardContent: {
    position: "absolute",
    width: "100%",
    bottom: '40%',
    top: '40%',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  progress: {
    margin: "auto",
  },
});

const Book = (props) => {
  function EditBook() {
    props.editBook(props.book);
  }

  function LaunchBookMenu() {
    props.showBookMenu(props.book);
  }

  function HandleClose() {
    props.hideBookMenu();
  }

  return(
    <Grid item key={props.book.id} sm={4} md={3} lg={2}>
      <CardActionArea onClick={LaunchBookMenu}>
      <Card className={props.classes.card}>
        <CardMedia
          className={props.classes.cardMedia}
          image={props.book.get("cover").url().replace("http://","https://")} // eslint-disable-line max-len
          title={props.book.get("title")}
        />
      </Card>
      </CardActionArea>
      <Dialog
       open={props.selected}
       onClose={HandleClose}
       scroll="body"
       aria-labelledby="form-dialog-title"
      >
         <DialogTitle id="form-dialog-title">{props.book.get("title")}</DialogTitle>
         <DialogContent>
           <DialogContentText>
              Visibilité du livre dans l'application
           </DialogContentText>
           <FormControl margin="normal" required fullWidth>
             <FormControlLabel
               control={
                 <Switch
                   checked={props.book.get("published")}
                   onChange={props.switchBookPublishState}
                   color="primary"
                 />
               }
               label={props.book.get("published") ? "Visible par tous" : "Privé (test)"}
             />
           </FormControl>
           <FormControl margin="normal" required fullWidth>
             <FormControlLabel
               control={
                 <Switch
                   checked={props.book.get("featured")}
                   onChange={props.switchBookFeaturedState}
                   color="primary"
                 />
               }
               label={props.book.get("featured") ? "En tête du catalogue" : "Pas de mise en avant"}
             />
           </FormControl>
           <FormControl margin="normal" fullWidth required>
             <InputLabel htmlFor="deleteConfirmationText">Rentrez le nom du livre pour le supprimer</InputLabel>
             <Input
               id="deleteConfirmationText"
               name="deleteConfirmationText"
               autoComplete="deleteConfirmationText"
               type="text"
               value={props.deleteConfirmationText || ""}
               onChange={(e) => props.changeDeleteConfirmationText(e.target.value)}
               required
               />
           </FormControl>
           <FormControl margin="normal" required>
           <Button variant="contained" onClick={props.deleteBook} disabled={!(props.deleteConfirmationText === props.book.get("title"))} color={props.deleteConfirmationText === props.book.get("title") ? "secondary" : "inherit"}>
             Supprimer
           </Button>
           </FormControl>
           <Typography variant="body2" align="left" color="error" paragraph style={props.deleteConfirmationText === "deletion_forbidden" ? null : {display: "none"}}>
            Impossible de supprimer le livre car des utilisateurs l'ont acheté ! Vous pouvez cependant le mettre en privé : ce dernier ne sera alors visible que par les acheteurs.
            </Typography>
         </DialogContent>
         <DialogActions>
           <Button onClick={HandleClose} color="primary">
             Fermer
           </Button>
           <Button onClick={EditBook} color="primary">
             Éditer
           </Button>
         </DialogActions>
       </Dialog>
    </Grid>
  );
}

const BookList = ({working, books, classes, editBook, showBookMenu, selectedBook,
  hideBookMenu, switchBookFeaturedState, switchBookPublishState,
  deleteConfirmationText, changeDeleteConfirmationText, deleteBook}) => {
  const bookNode = books.map((book) => {
    return (<Book
      book={book}
      key={book.id}
      classes={classes}
      editBook={editBook}
      showBookMenu={showBookMenu}
      selected={selectedBook ? (selectedBook.id === book.id) : false}
      hideBookMenu={hideBookMenu}
      switchBookPublishState={switchBookPublishState}
      switchBookFeaturedState={switchBookFeaturedState}
      deleteConfirmationText={deleteConfirmationText}
      changeDeleteConfirmationText={changeDeleteConfirmationText}
      deleteBook={deleteBook}
      />)
  });
  /*return (<ul>{bookNode}</ul>);*/
  if(books.length > 0) {
    return (
      <Grid container spacing={40}>
        {bookNode}
      </Grid>
    );
  }
  else if(working) {
    return (
      <CircularProgress className={classes.progress} />
    );
  }
  else {
    return (
      <Typography variant="body1" align="center" color="textSecondary" paragraph>
        Cliquez sur 'ajouter un livre' pour éditer votre premier livre.<br/>
        Vous pouvez différer la publication d'un livre créé.
      </Typography>
    );
  }
}

function BookCatalog(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Catalogue
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              L'ensemble des livres actuellement disponibles sur Inclood
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary" onClick={props.createBook}>
                    Ajouter un livre
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => {window.open(props.wikitudeLink)}}>
                    Gérer les cibles sur Wikitude
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        
        <div className={classNames(classes.layout, classes.cardGrid)} align="center">
          <BookList
            working={props.working}
            books={props.books}
            classes={classes}
            editBook={props.editBook}
            showBookMenu={props.showBookMenu}
            selectedBook={props.selectedBook}
            hideBookMenu={props.hideBookMenu}
            switchBookPublishState={props.switchBookPublishState}
            switchBookFeaturedState={props.switchBookFeaturedState}
            deleteConfirmationText={props.deleteConfirmationText}
            changeDeleteConfirmationText={props.changeDeleteConfirmationText}
            deleteBook={props.deleteBook}
          />
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Autres liens utiles
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.googleConsoleLink)}}>
                Console Google Play
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.appleConsoleLink)}}>
                Console Apple
              </Button>
            </Grid>
          </Grid>
        </div>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

BookCatalog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookCatalog);
