import AdEdit from './AdEdit.js'
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    fontSize: 64,
  },
  bigPlusIcon: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    fontSize: 64,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTargetAdd : {
    paddingTop: '50%', // 16:9
  },
  cardMedia: {
    paddingTop: '150%', // 16:9
  },
  squareCard: {
    width: '100%',
    paddingTop: '150%', // 16:9
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paper: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  littleProgress: {
    width:'auto',
    height: 'auto',
  }
});

function AddAdButton(props) {
  return (
    <Grid item key="newTarget" sm={4} md={3} lg={2}>
      <CardActionArea onClick={props.createAd}>
        <Card className={props.classes.squareCard} align="center">
              <AddCircleIcon className={props.classes.bigPlusIcon} color="action"/>
        </Card>
      </CardActionArea>
      <AdEdit
        books={props.books}
        adBookTitle={props.adBookTitle}
        adBookId={props.adBookId}
        editingAd={props.editingAd}
        discardAd={props.discardAd}
        saveAd={props.saveAd}
        adImage={props.adImage}
        changeAdImage={props.changeAdImage}
        changeAdBook={props.changeAdBook}

        working={props.working}
        uploading={props.uploading}
      />
    </Grid>
  );
}

function Ad(props) {
  function LaunchAdMenu() {
    props.showAdMenu(props.ad);
  }

  function HandleClose() {
    props.hideAdMenu();
  }

  function Edit() {
    props.editAd(props.ad);
  }

  function Delete() {
    props.removeSelectedAd();
  }

  return (
    <Grid item key={props.ad.id} sm={4} md={3} lg={2}>
    <CardActionArea
    id={"ad_" + props.ad.id}
    onClick={LaunchAdMenu}
    aria-owns={props.selected ? 'simple-menu' : undefined}
    aria-haspopup="true">
      <Card className={props.classes.card}>
        <CardMedia
          className={props.classes.cardMedia}
          image={props.ad.get("image").url().replace("http://","https://")} // eslint-disable-line max-len
          title={props.ad.get("book").get("title")}
        />
      </Card>
    </CardActionArea>
    <Menu
      id="simple-menu"
      open={props.selected}
      onClose={HandleClose}
      anchorEl={document.getElementById("ad_" + props.ad.id)}
    >
      <MenuItem onClick={Edit}>Éditer</MenuItem>
      <MenuItem onClick={Delete}>Supprimer</MenuItem>
    </Menu>
    </Grid>
  );
}

function AdsCatalog(props) {
  const { classes } = props;

  const ads = props.ads.map((ad,index) => {
    var selectedAd = props.selectedAd ? ad.id ===  props.selectedAd.id : false;
    return (<Ad
      ad={ad}
      key={ad.id}
      classes={props.classes}
      showAdMenu={props.showAdMenu}
      hideAdMenu={props.hideAdMenu}
      selected={selectedAd}
      editAd={props.editAd}
      removeSelectedAd={props.removeSelectedAd}
      />)
  });

  ads.push(AddAdButton(props));

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Publicités
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              L'ensemble des campagnes de publicité actives
            </Typography>
          </div>
        </div>
        <div className={classNames(classes.layout, classes.cardGrid)} align="center">
          <div className={classNames(props.classes.layout, props.classes.cardGrid)} align="center">
            <Grid container spacing={40}>
              {ads}
            </Grid>
          </div>
        </div>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Autres liens utiles
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.googleConsoleLink)}}>
                Console Google Play
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.appleConsoleLink)}}>
                Console Apple
              </Button>
            </Grid>
          </Grid>
        </div>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

AdsCatalog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdsCatalog);
