import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  littleIcon: {
    fontSize: 16,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    fontSize: 64,
  },
  bigPlusIcon: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    fontSize: 64,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    //paddingTop: '100%', // 16:9
    objectFit: 'cover',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paper: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  modalForm : {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
});

function AdEdit(props) {
  const bookSelection = props.books.map((book,index) => {
    return (<MenuItem key={book.id} value={index}>[{book.get("language")}] {book.get("title")}</MenuItem>
      )
  });

  function handleClose() {
    if(props.working) return;
    props.discardAd();
  }

  function forwardImageClick() {
    if(props.working) return;
    var adImageInput = document.querySelector("#adImage");
    adImageInput.click();
  }

  function submitForm(e) {
    e.preventDefault();
    if(props.working || props.uploading) return;
    props.saveAd();
  }

  return (
    <Dialog
     open={props.editingAd}
     onClose={handleClose}
     scroll="body"
     aria-labelledby="form-dialog-title"
    >
       <DialogTitle id="form-dialog-title">Campagne {props.adBookTitle}</DialogTitle>
       <form onSubmit={submitForm}>
        <DialogContent>
         <DialogContentText>
            Une campagne est liée à un livre et un seul. Les campagnes sont automatiquement montrées
            à l'utilisateur de la langue des livres associés à ces dernières. Une campagne n'est montrée
            qu'une seule fois à chaque utilisateur.
         </DialogContentText>
         <FormControl margin="normal" required fullWidth>
           <Card align="center" className={props.adImage ? null : props.classes.paper2}>
           {props.uploading ? <CircularProgress className={props.classes.progress} /> :
           props.adImage ?
             <CardActionArea onClick={forwardImageClick}>
               <CardMedia
                 className={props.classes.cardMedia}
                 component="img"
                 image={props.adImage.replace("http://","https://")} // eslint-disable-line max-len
               />
               <Input
                 id="adImage"
                 name="adImage"
                 autoComplete="adImage"
                 type="file"
                 inputProps={
                   {
                     accept: "image/jpeg, image/png"
                   }
                 }
                 style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                 onChange={(e) => props.changeAdImage(e.target.files[0])}
                 required={false}
               />
             </CardActionArea>
             :
             <>
               <Button onClick={forwardImageClick}>
                 <AddPhotoIcon className={props.classes.bigIcon} color="action"/>
                 <Input
                   id="adImage"
                   name="adImage"
                   autoComplete="adImage"
                   type="file"
                   inputProps={
                     {
                       accept: "image/jpeg, image/png"
                     }
                   }
                   style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                   onChange={(e) => props.changeAdImage(e.target.files[0])}
                   required
                 />
               </Button>
               <Typography variant="subtitle1">Veuillez télédéposer l'image (format conseillé: 750 x 1334)</Typography>
             </>
           }
           </Card>
         </FormControl>
         <FormControl margin="normal" required fullWidth>
             <InputLabel htmlFor="book">Livre promu</InputLabel>
             <Select
               value={props.books.map(b => b.id).indexOf(props.adBookId)}
               name="book"
               onChange={(e) => props.changeAdBook(props.books[e.target.value])}
               input={<Input id="book" align="left"/>}
             >
             {bookSelection}
             </Select>
           </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            disabled={props.working}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={props.uploading}
          >
            {props.working ? <CircularProgress color="white" size={20} />
              : "Sauvegarder"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

AdEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdEdit);
