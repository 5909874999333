import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(<App
  serverUrl={process.env.REACT_APP_SERVER_URL || 'https://localhost:1337/parse'}
  appId={process.env.REACT_APP_APP_ID || 'Inclood'}
  googleProductsLink={process.env.REACT_APP_GOOGLE_PRODUCTS_LINK}
  appleProductsLink={process.env.REACT_APP_APPLE_PRODUCTS_LINK}
  wikitudeLink={process.env.REACT_APP_WIKITUDE_LINK}
  appleConsoleLink={process.env.REACT_APP_APPLE_CONSOLE_LINK}
  googleConsoleLink={process.env.REACT_APP_GOOGLE_CONSOLE_LINK}
  firebasePushNotificationsLink={process.env.REACT_APP_FIREBASE_PUSH_NOTIFICATIONS_LINK}
/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
