import React from 'react';
import Parse from 'parse';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from 'react-router-dom'

import DenseAppBar from './AppBar.js'
import NotFound from './pages/NotFound.js'
import SignIn from './pages/SignIn.js'
import BookCatalog from './pages/BookCatalog.js'
import BookEdit from './pages/BookEdit.js'
import AdsCatalog from './pages/AdsCatalog.js'
import FreeAccesses from './pages/FreeAccesses.js'
import CustomizedSnackbar from './pages/SnackBar.js'
import UserList from './pages/UserList.js'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#009f9b',
    },
    secondary: {
      main: '#f78e4a',
    },
  },
});

// Container Component
class App extends React.Component{
  ModelDefaultImg = null;

  constructor(props){
    // Pass props to parent class
    super(props);

    Parse.initialize(props.appId, "", "");
    Parse.serverURL = props.serverUrl;

    const FreeAccessClass = Parse.Object.extend("FreeAccess");

    // Set initial state
    this.state = {
      data: [],
      loginForm: {
        email: "",
        password: ""
      },
      resetPasswordDialog: false,
      currentUser: Parse.User.current(),
      showingDrawer: false,

      books: [],
      bookEditStep: 0,
      selectedBook: null,
      deleteConfirmationText: "",
      editedBook: null,
      editedBookIsNew: false,
      selectedTarget: null,
      editedTarget: null,
      targetsToDelete: [],

      users: [],

      avatarFiles: [],

      ads: [],
      selectedAd: null,
      editedAd: null,

      freeAccesses: [],
      newFreeAccess: new FreeAccessClass(),

      uploading: false,
      working: false,
      errorMessage: null,
      successMessage: null,
    }
  }

  // Fetch passwords after first mount
  componentDidMount() {
    if(Parse.User.current()) {
      Parse.User.current().fetch().then((user) => {
        this.setState({currentUser: user, working: true});
        this.getBooks();
        this.getUsers();
        this.getAvatarFiles();
        this.getAds();
        this.getFreeAccesses();
        this.getStaticFiles();
      })
      .catch((e) => {
        Parse.User.logOut().then(() => {
          this.setState({currentUser: null});
        });
      });
    }
  }

  // GENERIC
  stateValueUpdater(field) {
    return (value) => {
      this.setState({[field]: value});
    }
  }

  parseStateValueUpdater(field, prop) {
    return (value) => {
      this.state[field].set(prop,value);
      this.setState({[field]: this.state[field]});
    }
  }

  parseStateCollectionValueUpdater(field, collection, prop) {
    return (index, value) => {
      var col = this.state[field].get(collection);
      col[index][prop] = value;
      this.state[field].set(collection,col);
      this.setState({[field]: this.state[field]});
    }
  }

  parseStateCollectionAddNew(field, collection) {
    return () => {
      var col = this.state[field].get(collection);
      col.push({});
      this.state[field].set(collection,col);
      this.setState({[field]: this.state[field]});
    }
  }

  parseStateCollectionRemoveAt(field, collection) {
    return (index) => {
      var col = this.state[field].get(collection);
      col.splice(index,1);
      this.state[field].set(collection,col);
      this.setState({[field]: this.state[field]});
    }
  }

  parseStateFileUpdater(field, prop) {
    //const delay = ms => new Promise(res => setTimeout(res, ms));

    return async (value) => {
      this.setState({uploading: true});
      try {
        var file = new Parse.File(value.name, value);
        file = await file.save();
        //await delay(5000);
        this.state[field].set(prop, file);
      } catch(e) {
        alert(e.statusText + " - " + e.responseText);
      }

      this.displaySuccess("Fichier télédéposé avec succès");

      this.setState({uploading:false, [field]: this.state[field]});
    }
  }

  displayError(message) {
    this.setState({errorMessage: message});
  }

  displaySuccess(message) {
    this.setState({successMessage: message});
  }

  /// LOGIN / LOGOUT
  async login(e) {
    e.preventDefault();

    try {
      var user = await Parse.User.logIn(this.state.loginForm.email, this.state.loginForm.password);
    } catch(e) {}

    if(user && user.get("isAdmin")){
      this.setState({currentUser: user, loginForm: {email: "", password: ""}, working: true});
      this.getBooks();
      this.getUsers();
      this.getAvatarFiles();
      this.getAds();
      this.getFreeAccesses();
    } else {
      this.setState({currentUser: null, loginForm: {email: "", password: ""}});
    }

    if(!this.state.currentUser) {
      this.displayError("Identifiants invalides");
    }
  }

  logout() {
    if(Parse.User.current()) {
      Parse.User.logOut()
      .then(() => {
        this.setState({currentUser: Parse.User.current()});
      });
    }
  }

  async resetPassword(e) {
    e.preventDefault();
    await Parse.User.requestPasswordReset(this.state.loginForm.email).then(() => {
      this.displaySuccess("Email envoyé");
      this.setState({resetPasswordDialog: false});
    }).catch((error) => {
      this.displayError("Erreur, veuillez vérifier l'e-mail");
    });
  }

  loginFormValueUpdater(field) {
    return (value) => {
      var loginForm = this.state.loginForm;
      loginForm[field] = value;
      this.setState({loginForm:loginForm});
    }
  }

  /// CATALOG
  async getBooks() {
      const BookClass = Parse.Object.extend("Book");
      const query = new Parse.Query(BookClass);
      var bs = await query.find();
      this.setState({books: bs, working: false});
  }

  async getUsers() {

      const result = await Parse.Cloud.run("listUsers");

      const users = result
        .filter(u => u.mail !== undefined)
        .map(u => u.mail.toLowerCase())
        .sort((u1, u2) => u1 > u2 ? 1 : -1);
        
      this.setState({ users, working: false })
  }

  /// 3D AVATAR FILES
  async getAvatarFiles() {
    const AvatarFileClass = Parse.Object.extend("AvatarFile");
    const query = new Parse.Query(AvatarFileClass);
    query.include("wt3File");
    query.include("fbxFile");
    var avs = await query.find();
    this.setState({avatarFiles: avs, working: false});
  }

  /// ADS
  async getAds() {
      const AdClass = Parse.Object.extend("Ad");
      const query = new Parse.Query(AdClass);
      var bs = await query.find();
      this.setState({ads: bs, working: false});
  }

  /// FREE ACCESSES
  async getFreeAccesses() {
      const FreeAccessClass = Parse.Object.extend("FreeAccess");
      const query = new Parse.Query(FreeAccessClass);
      var bs = await query.find();

      for(var i = 0; i < bs.length; i++) {
        var ac = bs[i];
        if(ac.get("expiryDate") < Date.now()) {
          await ac.destroy();
        }
      }

      bs = await query.find();

      this.setState({freeAccesses: bs, working: false});
  }

  /// PUSH NOTIFICATIONS
  async getStaticFiles() {
    const StaticFileClass = Parse.Object.extend("StaticFile");
    var query =  new Parse.Query(StaticFileClass);
    query.equalTo("name","3dModelDefaultImg");
    query.include("file");
    var staticFileObj = await query.first();
    this.ModelDefaultImg = staticFileObj.get("file");
  }

  /// EDIT
  createDefaultBook() {
    const BookClass = Parse.Object.extend("Book");
    var book = new BookClass();
    book.set("language","fr");
    book.set("nbSimultaneousTargets",1);
    book.set("nbBuyers",0);
    book.set("published",false);
    book.set("featured",false);
    book.set("targets",[]);
    book.set("isDownloadable",true);

    return book;
  }

  createBook() {
    this.setState({editedBook: this.createDefaultBook(), editedBookIsNew: true});
  }

  async editBook(book) {
    for(var i = 0; i < book.get("targets").length; i++) {
      if(book.get("targets")[i]) {
        try {
          book.get("targets")[i].fetch();
        } catch(e) {
          console.log("error, couldn't fetch target at position: " + i);
          book.set("targets",book.get("targets").splice(i,1));
          i--;
        }
      } else {
        console.log("error, couldn't fetch target at position: " + i);
        book.set("targets",book.get("targets").splice(i,1));
        i--;
      }
    }

    this.setState({selectedBook: null, editedBook: book, editedBookIsNew: false});
  }

  async saveBook() {
    this.setState({working: true});

    //const delay = ms => new Promise(res => setTimeout(res, ms));


    try {
      var isExistingBook = this.state.editedBook.id ? true : false;
      var book = await this.state.editedBook.save();

      // try deleting the targets
      try {
        if(this.state.targetsToDelete) {
          for(var i = 0; i < this.state.targetsToDelete.length; i++) {
            var target = this.state.targetsToDelete[i];
            if(target.get("spriteSheet"))
              var fileUrl = target.get("spriteSheet").url();
            target.destroy().then(() => {
              if(fileUrl) {
                Parse.cloud.run("deleteFile", {"fileUrl" : fileUrl}).then(() => {

                });
              }
            });
          }
        }
      } catch(e) {}

      //await delay(5000);

      if(!isExistingBook) {
        this.state.books.push(book);
      } else {
        var index = this.state.books.findIndex(b => b.id === book.id);
        this.state.books[index] = book;
      }

      this.setState({books: this.state.books, targetsToDelete: []});

      this.displaySuccess("Le livre \"" + book.get("title") + "\" a été modifié/créé avec succès");
    } catch(e) {
      this.displayError("Erreur lors de la création du livre");
    }

    this.setState({working: false});
  }

  async publishSelectedBook() {
    if(!this.state.selectedBook) return;

    this.setState({working: true});

    this.state.selectedBook.set("published",!this.state.selectedBook.get("published"));
    this.state.selectedBook = await this.state.selectedBook.save();

    this.displaySuccess(this.state.selectedBook.get("published") ? "Publication confirmée" : "Dépublication confirmée");
    this.setState({working: false, selectedBook: this.state.selectedBook});
  }

  async featureSelectedBook() {
    if(!this.state.selectedBook) return;

    this.setState({working: true});

    this.state.selectedBook.set("featured",!this.state.selectedBook.get("featured"));
    this.state.selectedBook = await this.state.selectedBook.save();

    if(this.state.selectedBook.get("featured")) {
      var booksToUnfeature = this.state.books.filter((b) =>
      b.get("language") === this.state.selectedBook.get("language")
      && b.get("featured")
      && b !== this.state.selectedBook);
      for(var i = 0; i < booksToUnfeature.length; i++) {
        var b = booksToUnfeature[i];
        b.set("featured",false);
        b = await b.save();
      }
    }

    this.displaySuccess(this.state.selectedBook.get("featured") ? "Mise en avant confirmée" : "Mise en avant retirée");
    this.setState({working: false, selectedBook: this.state.selectedBook, books: this.state.books});
  }

  async deleteSelectedBook() {
    if(!this.state.selectedBook) return;

    this.setState({working: true});

    // First check if nobody bought the book
    const UserClass = Parse.Object.extend("User");
    const query = new Parse.Query(UserClass);
    query.equalTo("books", this.state.selectedBook);
    var buyers = await query.find();

    if(buyers && buyers.length > 0) {
      this.setState({working: false, deleteConfirmationText: "deletion_forbidden"});
      return;
    }

    var id = this.state.selectedBook.id;
    try {
      this.state.selectedBook = await this.state.selectedBook.destroy();
      var bookPosition = this.state.books.findIndex(t => t.id === id);
      this.state.books.splice(bookPosition,1);
      this.displaySuccess("Suppression confirmée");
    } catch {
      this.displayError("Suppression impossible");
    }

    this.setState({working: false, selectedBook: this.state.selectedBook, books: this.state.books});
  }

  finishBookEdit() {
    var coverUrl = this.state.editedBook.get("cover") ? this.state.editedBook.get("cover").url() : null;
    var headerImageUrl = this.state.editedBook.get("headerImage") ? this.state.editedBook.get("headerImage").url() : null;
    var wtcUrl = this.state.editedBook.get("arDatabase") ? this.state.editedBook.get("arDatabase").url() : null;

    if(!this.state.editedBook.id) {
      if(coverUrl) Parse.Cloud.run("deleteFile", {"fileUrl" : coverUrl});
      if(headerImageUrl) Parse.Cloud.run("deleteFile", {"fileUrl" : headerImageUrl});
      if(wtcUrl) Parse.Cloud.run("deleteFile", {"fileUrl" : wtcUrl});
    } else {
      this.state.editedBook.revert();

      if(coverUrl && coverUrl !== this.state.editedBook.get("cover").url()) {
        Parse.Cloud.run("deleteFile", {"fileUrl" : coverUrl});
      }

      if(headerImageUrl && headerImageUrl !== this.state.editedBook.get("headerImage").url()) {
        Parse.Cloud.run("deleteFile", {"fileUrl" : headerImageUrl});
      }

      if(wtcUrl && wtcUrl !== this.state.editedBook.get("arDatabase").url()) {
        Parse.Cloud.run("deleteFile", {"fileUrl" : wtcUrl});
      }
    }

    this.setState({books: this.state.books, editedBook: null, bookEditStep: 0, targetsToDelete: []});
  }

  createDefaultTarget() {
    const TargetClass = Parse.Object.extend("Target");
    var target = new TargetClass();
    target.set("targetType","2D");
    target.set("videos",[{uri: "", name: "undefined", color: "#302542"}]);
    target.set("spriteSheetColumns", 4);
    target.set("animationSpeed",5);
    target.set("spriteSheetNbSprites", 16);
    target.set("avatarAnimationIdx",0);
    target.set("zoom","1.0");
    target.set("xOffset","0.0");
    target.set("yOffset","0.0");

    return target;
  }

  createTarget() {
    this.setState({editedTarget: this.createDefaultTarget()});
  }

  editTarget(target) {
    this.setState({selectedTarget: null, editedTarget: target});
  }

  changeTargetType(type) {
    this.state.editedTarget.set("type",type);
    var videos = this.state.editedTarget.get("videos");

    if(type === "2D" && (!videos || videos.length === 0)) {
      this.state.editedTarget.set("videos",[{uri: "", name: "undefined", color: "#302542"}]);
    }
    else if (type === "3D") {
      this.state.editedTarget.set("spriteSheet",this.ModelDefaultImg);
    }

    this.setState({editedTarget: this.state.editedTarget});
  }

  async discardTarget() {
    var spriteSheet = this.state.editedTarget.get("spriteSheet") ? this.state.editedTarget.get("spriteSheet").url() : null;

    if(!this.state.editedTarget.id) {
      if(spriteSheet) Parse.Cloud.run("deleteFile", {"fileUrl" : spriteSheet});
    } else {
      this.state.editedTarget.revert();

      if(spriteSheet && spriteSheet !== this.state.editedTarget.get("spriteSheet")?.url()) {
        Parse.Cloud.run("deleteFile", {"fileUrl" : spriteSheet});
      }
    }

    this.setState({editedBook: this.state.editedBook, editedTarget: null});
  }

  async saveTarget(e) {
    //const delay = ms => new Promise(res => setTimeout(res, ms));

    if(e) e.preventDefault();

    this.setState({working: true});

    this.state.editedTarget = await this.state.editedTarget.save();
    //await delay(5000);

    var targets = this.state.editedBook.get("targets");
    var position = targets.findIndex(t => t.id === this.state.editedTarget.id);

    if(position < 0) {
      targets.push(this.state.editedTarget);
    }
    else {
      targets[position] = this.state.editedTarget;
    }

    this.state.editedBook.set("targets", targets);
    this.setState({editedTarget: null, editedBook: this.state.editedBook, working: false});
  }

  removeSelectedTarget() {
    var targets = this.state.editedBook.get("targets");
    var targetPosition = targets.findIndex(t => t.id === this.state.selectedTarget.id);
    targets.splice(targetPosition,1);


    var targetsToDelete = this.state.targetsToDelete;
    targetsToDelete.push(this.state.selectedTarget);

    this.state.editedBook.set("targets", targets);
    this.setState({selectedTarget: null, editedBook: this.state.editedBook, targetsToDelete: targetsToDelete});
  }

  // AVATAR FILES
  async addAvatarFiles(wt3File, fbxFile) {
    const AvatarFileClass = Parse.Object.extend("AvatarFile");
    var av = new AvatarFileClass();

    this.setState({uploading: true});
    try {
      var parseWt3File = new Parse.File(wt3File.name, wt3File);
      parseWt3File = await parseWt3File.save();
      var parseFbxFile = new Parse.File(fbxFile.name, fbxFile);
      parseFbxFile = await parseFbxFile.save();
      //await delay(5000);
      av.set("wt3File", parseWt3File);
      av.set("fbxFile", parseFbxFile);
      av.set("name", wt3File.name.substr(0,wt3File.name.length-4));
      av = await av.save();
    } catch(e) {
      alert(e.statusText + " - " + e.responseText);
    }

    this.displaySuccess("Fichier télédéposé avec succès");
    this.state.avatarFiles.push(av);
    this.setState({uploading:false, avatarFiles: this.state.avatarFiles});
  }

  // ADS
  createDefaultAd() {
    const AdClass = Parse.Object.extend("Ad");
    var ad = new AdClass();
    ad.set("book", this.state.books[0]);

    return ad;
  }

  createAd() {
    this.setState({editedAd: this.createDefaultAd()});
  }

  editAd(ad) {
    this.setState({selectedAd: null, editedAd: ad});
  }

  async discardAd() {
    var adImage = this.state.editedAd.get("image") ? this.state.editedAd.get("image").url() : null;

    if(!this.state.editedAd.id) {
      if(adImage) Parse.Cloud.run("deleteFile", {"fileUrl" : adImage});
    } else {
      this.state.editedAd.revert();

      if(adImage && adImage !== this.state.editedAd.get("image").url()) {
        Parse.Cloud.run("deleteFile", {"fileUrl" : adImage});
      }
    }

    this.setState({ads: this.state.ads, editedAd: null});
  }

  async saveAd(e) {
    if(e) e.preventDefault();

    this.setState({working: true});

    this.state.editedAd = await this.state.editedAd.save();

    var position = this.state.ads.findIndex(t => t.id === this.state.editedAd.id);

    if(position < 0) {
      this.state.ads.push(this.state.editedAd);
    }
    else {
      this.state.ads[position] = this.state.editedAd;
    }

    this.setState({editedAd: null, ads: this.state.ads, working: false});
  }

  removeSelectedAd() {
    var position = this.state.ads.findIndex(t => t.id === this.state.selectedAd.id);
    this.state.ads.splice(position,1);

    if(this.state.selectedAd.get("image"))
      var fileUrl = this.state.selectedAd.get("image").url();
    this.state.selectedAd.destroy().then(() => {
      if(fileUrl) {
        Parse.cloud.run("deleteFile", {"fileUrl" : fileUrl}).then(() => {

        });
      }
    });

    this.setState({selectedAd: null, ads: this.state.ads});
  }

  /// FREE ACCESSES

  async addNewFreeAccess(e) {
    if(e) e.preventDefault();

    this.setState({working: true});

    var newFreeAccess = await this.state.newFreeAccess.save();
    var freeAccesses = this.state.freeAccesses;
    freeAccesses.push(newFreeAccess);

    const FreeAccessClass = Parse.Object.extend("FreeAccess");
    var prevDate = newFreeAccess.get("expiryDate");
    newFreeAccess = new FreeAccessClass();
    newFreeAccess.set("expiryDate", prevDate);
    this.setState({freeAccesses: freeAccesses, working: false, newFreeAccess: newFreeAccess});
  }

  deleteFreeAccess(ac) {
    var position = this.state.freeAccesses.findIndex(t => t.id === ac.id);
    var freeAccesses = this.state.freeAccesses;
    freeAccesses.splice(position,1);
    this.setState({freeAccesses: freeAccesses});

    ac.destroy();
  }

  resetState() {
    if(this.state.editedTarget) this.discardTarget();
    if(this.state.editedAd) this.discardAd();
    if(this.state.editedBook) this.finishBookEdit();
    this.setState({selectedTarget: null, selectedAd: null, selectedBook: null, showingDrawer: false});
  }

  backHome() {
    this.resetState();
  }

  openAdsCatalog() {
    this.resetState();
  }

  openFreeAccesses() {
    this.resetState();

  }

  render(){
    // Render JSX
    return (
      <Router>
        <MuiThemeProvider theme={theme}>
          <DenseAppBar
            title="Inclood App Manager"
            logout={this.logout.bind(this)}
            user={this.state.currentUser}
            showingDrawer={this.state.showingDrawer}
            switchDrawer={this.stateValueUpdater("showingDrawer").bind(this)}
            backHome={this.backHome.bind(this)}
            adsCatalog={this.openAdsCatalog.bind(this)}
            freeAccesses={this.openFreeAccesses.bind(this)}
            appleConsoleLink={this.props.appleConsoleLink}
            googleConsoleLink={this.props.googleConsoleLink}
            firebasePushNotificationsLink={this.props.firebasePushNotificationsLink}
            wikitudeLink={this.props.wikitudeLink}
          />
          <Switch>
          <Route exact path="/login" render={() => !this.state.currentUser ?
              <SignIn
                emailValue={this.state.loginForm.email}
                emailChange={this.loginFormValueUpdater("email").bind(this)}
                passwordValue={this.state.loginForm.password}
                passwordChange={this.loginFormValueUpdater("password").bind(this)}
                submit={this.login.bind(this)}
                resetPasswordDialog={this.state.resetPasswordDialog}
                hideResetPasswordDialog={() => this.stateValueUpdater("resetPasswordDialog").bind(this)(false)}
                showResetPasswordDialog={() => this.stateValueUpdater("resetPasswordDialog").bind(this)(true)}
                resetPassword={this.resetPassword.bind(this)}
              /> :
              <Redirect to='/' />
            }
          />
          <Route exact path="/" render={() => {
              if(!this.state.currentUser)
                return (<Redirect to="/login" />);

              if(this.state.editedBook) {
                return (<Redirect to='/editBook' />);
              }

              return(
                <BookCatalog
                  working={this.state.working}
                  books={this.state.books}
                  createBook={this.createBook.bind(this)}
                  editBook={this.editBook.bind(this)}
                  showBookMenu={this.stateValueUpdater("selectedBook").bind(this)}
                  selectedBook={this.state.selectedBook}
                  deleteConfirmationText={this.state.deleteConfirmationText}
                  hideBookMenu={() => {this.stateValueUpdater("selectedBook").bind(this)(null)}}
                  switchBookFeaturedState={this.featureSelectedBook.bind(this)}
                  switchBookPublishState={this.publishSelectedBook.bind(this)}
                  changeDeleteConfirmationText={this.stateValueUpdater("deleteConfirmationText").bind(this)}
                  deleteBook={this.deleteSelectedBook.bind(this)}
                  appleConsoleLink={this.props.appleConsoleLink}
                  googleConsoleLink={this.props.googleConsoleLink}
                  wikitudeLink={this.props.wikitudeLink}
                />
              );
            }
          }
          />
          <Route exact path="/editBook" render={() => {
              if(!this.state.currentUser)
                return (<Redirect to="/login" />);

              if(!this.state.editedBook) {
                return (<Redirect to='/' />);
              }

              // extract the number in the target id for comparaison
              var sortTargets = (a,b) => {
                var regexNb = new RegExp("[^0-9]*","g");

                var aId = a.get("wikitudeId");
                var bId = b.get("wikitudeId");

                if(!aId) return false;
                else if(!bId) return false;

                if(aId[0] !== bId[0]) return aId[0] > bId[0];

                var nbA = aId.replace(regexNb,"") * 1;
                var nbB = bId.replace(regexNb,"") * 1;

                return nbA > nbB;
              }

              return(
                <BookEdit
                  displayError={this.displayError.bind(this)}
                  displaySuccess={this.displaySuccess.bind(this)}

                  appleProductsLink={this.props.appleProductsLink}
                  googleProductsLink={this.props.googleProductsLink}
                  wikitudeLink={this.props.wikitudeLink}

                  editedBookIsNew={this.state.editedBookIsNew}
                  saveBook={this.saveBook.bind(this)}
                  finishBookEdit={this.finishBookEdit.bind(this)}

                  working={this.state.working}
                  uploading={this.state.uploading}
                  activeStep={this.state.bookEditStep}
                  changeStep={this.stateValueUpdater("bookEditStep").bind(this)}

                  language={this.state.editedBook && this.state.editedBook.get("language")}
                  title={this.state.editedBook && this.state.editedBook.get("title")}
                  author={this.state.editedBook && this.state.editedBook.get("author")}
                  editor={this.state.editedBook && this.state.editedBook.get("editor")}
                  sampleVideo={this.state.editedBook && this.state.editedBook.get("sampleVideo")}
                  shortDescription={this.state.editedBook && this.state.editedBook.get("shortDescription")}
                  description={this.state.editedBook && this.state.editedBook.get("description")}
                  cover={this.state.editedBook && this.state.editedBook.get("cover") && this.state.editedBook.get("cover").url()}
                  headerImage={this.state.editedBook && this.state.editedBook.get("headerImage") && this.state.editedBook.get("headerImage").url()}
                  changeLanguage={this.parseStateValueUpdater("editedBook","language").bind(this)}
                  changeTitle={this.parseStateValueUpdater("editedBook","title").bind(this)}
                  changeAuthor={this.parseStateValueUpdater("editedBook","author").bind(this)}
                  changeEditor={this.parseStateValueUpdater("editedBook","editor").bind(this)}
                  changeSampleVideo={this.parseStateValueUpdater("editedBook","sampleVideo").bind(this)}
                  changeShortDescription={this.parseStateValueUpdater("editedBook","shortDescription").bind(this)}
                  changeDescription={this.parseStateValueUpdater("editedBook","description").bind(this)}
                  changeCover={this.parseStateFileUpdater("editedBook","cover").bind(this)}
                  changeHeaderImage={this.parseStateFileUpdater("editedBook","headerImage").bind(this)}

                  appleProductId={this.state.editedBook && this.state.editedBook.get("appleProductId")}
                  googleProductId={this.state.editedBook && this.state.editedBook.get("googleProductId")}
                  partnerLinkUrl={this.state.editedBook && this.state.editedBook.get("partnerUrl")}
                  changeAppleProductId={this.parseStateValueUpdater("editedBook","appleProductId").bind(this)}
                  changeGoogleProductId={this.parseStateValueUpdater("editedBook","googleProductId").bind(this)}
                  changePartnerLinkUrl={this.parseStateValueUpdater("editedBook","partnerUrl").bind(this)}

                  wtcFile={this.state.editedBook && this.state.editedBook.get("arDatabase")}
                  changeWtcFile={this.parseStateFileUpdater("editedBook","arDatabase").bind(this)}
                  targets={this.state.editedBook && this.state.editedBook.get("targets").sort(sortTargets)}

                  createTarget={this.createTarget.bind(this)}
                  editTarget={this.editTarget.bind(this)}
                  saveTarget={this.saveTarget.bind(this)}
                  editingTarget={this.state.editedTarget ? true : false}
                  selectedTarget={this.state.selectedTarget}
                  removeSelectedTarget={this.removeSelectedTarget.bind(this)}
                  showTargetMenu={this.stateValueUpdater("selectedTarget").bind(this)}
                  hideTargetMenu={() => {this.stateValueUpdater("selectedTarget").bind(this)(null)}}
                  discardTarget={this.discardTarget.bind(this)}
                  nbSimultaneousTargets={this.state.editedBook && this.state.editedBook.get("nbSimultaneousTargets")}
                  changeNbSimultaneousTargets={this.parseStateValueUpdater("editedBook", "nbSimultaneousTargets").bind(this)}
                  isBookDownloadable={this.state.editedBook && this.state.editedBook.get("isDownloadable")}
                  switchIsBookDownloadable={this.parseStateValueUpdater("editedBook","isDownloadable").bind(this)}

                  targetType={this.state.editedTarget && (this.state.editedTarget.get("type") || "2D")}
                  targetWikitudeId={this.state.editedTarget && this.state.editedTarget.get("wikitudeId")}
                  targetSpriteSheet={this.state.editedTarget && this.state.editedTarget.get("spriteSheet") && this.state.editedTarget.get("spriteSheet").url()}
                  targetSpriteSheetNbSprites={this.state.editedTarget && this.state.editedTarget.get("spriteSheetNbSprites")}
                  targetSpriteSheetColumns={this.state.editedTarget && this.state.editedTarget.get("spriteSheetColumns")}
                  targetAnimationSpeed={this.state.editedTarget && this.state.editedTarget.get("animationSpeed")}
                  targetXOffset={this.state.editedTarget && this.state.editedTarget.get("xOffset")}
                  targetYOffset={this.state.editedTarget && this.state.editedTarget.get("yOffset")}
                  targetZoom={this.state.editedTarget && this.state.editedTarget.get("zoom")}
                  changeTargetType={this.changeTargetType.bind(this)}
                  changeTargetWikitudeId={this.parseStateValueUpdater("editedTarget","wikitudeId").bind(this)}
                  changeTargetSpriteSheet={this.parseStateFileUpdater("editedTarget","spriteSheet").bind(this)}
                  changeTargetSpriteSheetNbSprites={this.parseStateValueUpdater("editedTarget","spriteSheetNbSprites").bind(this)}
                  changeTargetSpriteSheetColumns={this.parseStateValueUpdater("editedTarget","spriteSheetColumns").bind(this)}
                  changeTargetAnimationSpeed={this.parseStateValueUpdater("editedTarget","animationSpeed").bind(this)}
                  changeTargetXOffset={this.parseStateValueUpdater("editedTarget","xOffset").bind(this)}
                  changeTargetYOffset={this.parseStateValueUpdater("editedTarget","yOffset").bind(this)}
                  changeTargetZoom={this.parseStateValueUpdater("editedTarget","zoom").bind(this)}

                  avatarFiles={this.state.avatarFiles}
                  targetAvatarFile={this.state.editedTarget && this.state.editedTarget.get("avatarFile")}
                  targetAvatarAnimationId={this.state.editedTarget && this.state.editedTarget.get("avatarAnimationId")}
                  changeTargetAvatarFile={this.parseStateValueUpdater("editedTarget","avatarFile").bind(this)}
                  addAvatarFiles={this.addAvatarFiles.bind(this)}
                  changeTargetAvatarAnimationId={this.parseStateValueUpdater("editedTarget","avatarAnimationId").bind(this)}

                  targetVideos={(this.state.editedTarget && this.state.editedTarget.get("videos")) || []}
                  changeTargetVideoUri={this.parseStateCollectionValueUpdater("editedTarget","videos","uri").bind(this)}
                  changeTargetVideoVimeoId={this.parseStateCollectionValueUpdater("editedTarget","videos","vimeoId").bind(this)}
                  changeTargetVideoName={this.parseStateCollectionValueUpdater("editedTarget","videos","name").bind(this)}
                  changeTargetVideoColor={this.parseStateCollectionValueUpdater("editedTarget","videos","color").bind(this)}
                  addTargetVideo={this.parseStateCollectionAddNew("editedTarget", "videos").bind(this)}
                  removeTargetVideo={this.parseStateCollectionRemoveAt("editedTarget", "videos")}
                />
              );
            }
          }
          />
          <Route exact path="/ads" render={() => {
              if(!this.state.currentUser)
                return (<Redirect to="/login" />);

              return(
                <AdsCatalog
                  working={this.state.working}
                  uploading={this.state.uploading}
                  ads={this.state.ads}
                  books={this.state.books}
                  createAd={this.createAd.bind(this)}
                  editAd={this.editAd.bind(this)}
                  saveAd={this.saveAd.bind(this)}
                  editingAd={this.state.editedAd ? true : false}
                  selectedAd={this.state.selectedAd}
                  removeSelectedAd={this.removeSelectedAd.bind(this)}
                  showAdMenu={this.stateValueUpdater("selectedAd").bind(this)}
                  hideAdMenu={() => {this.stateValueUpdater("selectedAd").bind(this)(null)}}
                  discardAd={this.discardAd.bind(this)}

                  adImage={this.state.editedAd && this.state.editedAd.get("image") && this.state.editedAd.get("image").url()}
                  adBookTitle={this.state.editedAd && this.state.editedAd.get("book") && this.state.editedAd.get("book").get("title")}
                  adBookId = {this.state.editedAd && this.state.editedAd.get("book") && this.state.editedAd.get("book").id}
                  changeAdImage={this.parseStateFileUpdater("editedAd","image").bind(this)}
                  changeAdBook={this.parseStateValueUpdater("editedAd","book").bind(this)}
                />
              );
          }}
          />
          <Route exact path="/freeAccesses" render={() => {
              if(!this.state.currentUser)
                return (<Redirect to="/login" />);

              return(
                <FreeAccesses
                  working={this.state.working}
                  uploading={this.state.uploading}
                  freeAccesses={this.state.freeAccesses}
                  removeFreeAccess={this.deleteFreeAccess.bind(this)}
                  newFreeAccess={this.state.newFreeAccess}
                  changeNewFreeAccessEmail={this.parseStateValueUpdater("newFreeAccess","email").bind(this)}
                  changeNewFreeAccessExpiryDate={this.parseStateValueUpdater("newFreeAccess", "expiryDate").bind(this)}
                  addNewFreeAccess={this.addNewFreeAccess.bind(this)}
                />
              );
          }}
          />
          <Route exact path="/user-list" render={() => {
              if(!this.state.currentUser)
                return (<Redirect to="/login" />);

              return(
                <UserList
                  working={this.state.working}
                  users={this.state.users}
                />
              );
          }}
          />
          <Route component={NotFound} />
          </Switch>
          <CustomizedSnackbar variant="error" message={this.state.errorMessage} onClose={() => {this.setState({errorMessage: null})}} />
          <CustomizedSnackbar variant="success" message={this.state.successMessage} onClose={() => {this.setState({successMessage: null})}} />
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
