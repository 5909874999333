import React, { useState, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ListItemText, ListItem, List, TextField, Button } from '@material-ui/core';
import { authEndpoints } from 'vimeo/lib/vimeo';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    icon: {
        marginRight: theme.spacing.unit * 2,
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        paddingTop: theme.spacing.unit * 8,
        paddingBottom: theme.spacing.unit * 4,
    },
    heroButtons: {
        marginBottom: theme.spacing.unit * 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    searchField: {

    },
    searchButton: {
        marginLeft: theme.spacing.unit * 2
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    userList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '25px'
    },
    userItem: {
        width: '75%',
        maxWidth: 400,
        margin: 'auto',
        paddingTop: '6px',
        paddingBottom: '6px',
    }
})


function UserList(props) {
    const { classes, users } = props;

    const [ searchText, setSearchText ] = useState("");

    const displayedUsers = useMemo(() => {

        const displayedUsers = users.filter(u => u.includes(searchText));

        return <div className={classes.layout} align="center">
            {displayedUsers.length > 0 ? <List className={classes.userList}>
                {displayedUsers.map((user, i) => <ListItem className={classes.userItem} key={user}>
                    <ListItemText primary={user}/>
                </ListItem>)}
            </List> :
            <Typography>
                Aucun utilisateur trouvé
            </Typography>}
        </div>
        
    }, [users, Math.max(searchText.length - 2, 0)])

    return <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
            <div className={classes.heroContent}>
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Utilisateurs
                </Typography>
                <Typography variant="h6" align="center" color="textSecondary" paragraph>
                    L'ensemble des utilisateurs de l'application
                </Typography>
            </div>
            <div className={classes.heroButtons}>
                <TextField
                    id="standard-search"
                    label="Rechercher"
                    type="search"
                    className={classes.searchField}
                    margin="normal"
                    value={searchText}
                    onChange={val => setSearchText(val.target.value)}
                />
                <Button
                    variant="contained"
                    className={classes.searchButton}
                    disabled={searchText.length === 0}
                    onClick={() => {
                        setSearchText("");
                    }}
                >Réinitialiser</Button>
            </div>
        </div>

        {displayedUsers}
        
    </main>
}

export default withStyles(styles)(UserList);