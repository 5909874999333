import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ClearIcon from '@material-ui/icons/Clear';
import EmailIcon from '@material-ui/icons/Email';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    fontSize: 64,
  },
  bigPlusIcon: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    fontSize: 64,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  layout2: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: `${theme.spacing.unit * 8}px`,
    }
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTargetAdd : {
    paddingTop: '50%', // 16:9
  },
  cardMedia: {
    paddingTop: '150%', // 16:9
  },
  squareCard: {
    width: '100%',
    paddingTop: '150%', // 16:9
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paper: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  littleProgress: {
    width:'auto',
    height: 'auto',
  },
  table: {

  }
});

function FreeAccesses(props) {
  const { classes } = props;

  function submitForm(e) {
    e.preventDefault();
    if(props.working || props.uploading) return;
    props.addNewFreeAccess(e);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Accès Presse
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
              L'ensemble des e-mails bénéficiant d'un accès gratuit au catalogue
            </Typography>
          </div>
        </div>
        <div className={classes.layout}>
          <Paper className={classes.paper2}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Adresse e-mail</TableCell>
                    <TableCell align="right">Date d'expiration</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.freeAccesses.map(ac => (
                    <TableRow key={ac.id}>
                      <TableCell component="th" scope="row">
                        {ac.get("email")}
                      </TableCell>
                      <TableCell align="right">{ac.get("expiryDate").toLocaleDateString()}</TableCell>
                      <TableCell align="right">
                        <Button
                          style={{margin: "auto"}}
                          onClick={() => {props.removeFreeAccess(ac)}}
                          disabled={props.working}
                          >
                          <ClearIcon className={props.classes.littleIcon} color="action"/>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
          </Paper>
        </div>
        <div className={classes.layout2}>
            <form onSubmit={submitForm}>
              <Grid container spacing={32} alignItems="center">
                <Grid item xs={6}>
                  <FormControl margin="normal" required fullWidth>
                    <InputLabel htmlFor="newAccountEmail">Adresse e-mail</InputLabel>
                    <Input
                      value={props.newFreeAccess.get("email") || ""}
                      onChange={e => props.changeNewFreeAccessEmail(e.target.value)}
                      id="newAccountEmail"
                      label="Adresse e-mail"
                      type="email"
                      required
                      disabled={props.working}
                      startAdornment={
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs="4">
                  <FormControl margin="normal" required fullWidth>
                    <TextField
                      id="date"
                      label="Date d'expiration"
                      type="date"
                      onChange={e => props.changeNewFreeAccessExpiryDate(e.target.valueAsDate)}
                      className={classes.textField}
                      required
                      disabled={props.working}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={props.working}
                    fullWidth
                  >
                    {props.working ? <CircularProgress color="white" size={20} />
                      : "Ajouter"}
                  </Button>
                </Grid>
              </Grid>
            </form>
        </div>
      </main>
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Autres liens utiles
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.googleConsoleLink)}}>
                Console Google Play
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.appleConsoleLink)}}>
                Console Apple
              </Button>
            </Grid>
          </Grid>
        </div>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

FreeAccesses.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FreeAccesses);
