import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

function SignIn(props) {
  const { classes } = props;

  function HandleClose() {
    props.hideResetPasswordDialog();
  }

  return (
    <div>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Connexion administrateur
          </Typography>
          <form className={classes.form} onSubmit={props.submit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="email">Adresse Email</InputLabel>
              <Input
                id="email"
                name="email"
                autoComplete="email"
                type="email"
                autoFocus
                value={props.emailValue}
                onChange={(e) => props.emailChange(e.target.value)}
                />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Mot de passe</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={props.passwordValue}
                onChange={(e) => props.passwordChange(e.target.value)}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Connexion
            </Button>
            <Button
              onClick={props.showResetPasswordDialog}
              fullWidth
              className={classes.submit}
            >
              changer le mot de passe
            </Button>
          </form>
        </Paper>
      </main>
      <Dialog
       open={props.resetPasswordDialog}
       onClose={HandleClose}
       scroll="body"
       aria-labelledby="form-dialog-title"
      >
         <DialogTitle id="form-dialog-title">Changement du mot de passe</DialogTitle>
         <DialogContentText>
          Si cet e-mail est administrateur, vous receverez un lien de mise à jour du mot de passe sur ce dernier.
         </DialogContentText>
         <DialogContent>
          <form className={classes.form} onSubmit={props.resetPassword}>
           <FormControl margin="normal" required fullWidth>
             <InputLabel htmlFor="email">Adresse Email</InputLabel>
             <Input
               id="email"
               name="email"
               autoComplete="email"
               type="email"
               autoFocus
               value={props.emailValue}
               onChange={(e) => props.emailChange(e.target.value)}
               />
           </FormControl>
           <Button
             type="submit"
             fullWidth
             variant="contained"
             color="primary"
             className={classes.submit}
           >
             Envoyer
           </Button>
           </form>
         </DialogContent>
         <DialogActions>
           <Button onClick={HandleClose} color="primary">
             Fermer
           </Button>
         </DialogActions>
       </Dialog>
    </div>
  );
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignIn);
