import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import AddFileIcon from '@material-ui/icons/NoteAdd';
import ClearIcon from '@material-ui/icons/Clear';
import LinkIcon from '@material-ui/icons/Link';
import OndemandVideo from '@material-ui/icons/OndemandVideo';
import TitleIcon from '@material-ui/icons/Title';
import ColorIcon from '@material-ui/icons/ColorLens';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  littleIcon: {
    fontSize: 16,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    fontSize: 64,
  },
  bigPlusIcon: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    fontSize: 64,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    //paddingTop: '100%', // 16:9
    objectFit: 'cover',
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paper: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  modalForm : {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  targetTypeButtons: {
    marginLeft: -15
  },
  targetTypeRadioButton: {
    marginLeft: 0
  },
  avatarSelect: {
    textAlign: "left"
  }
});

function TargetEdit(props) {
  function handleClose() {
    if(props.working) return;
    props.discardTarget();
  }

  function forwardImageClick() {
    if(props.working) return;
    var spriteSheetInput = document.querySelector("#spriteSheet");
    spriteSheetInput.click();
  }

  function forwardAddAvatarClick() {
    if(props.working) return;
    var avatarFileInput = document.querySelector("#avatarFile");
    avatarFileInput.click();
  }

  function selectAvatarFile(e) {
    if(props.working) return;
     props.changeTargetAvatarFile(props.avatarFiles.find(af => af.id === e.target.value));
  }

  function addAvatarFiles(files) {
    if(files.length != 2) {
      props.displayError("veuillez sélectionner un fichier WT3 et GLB ou FBX");
      return;
    }

    if(files[0].name.substr(-3) === "wt3") {
      if(files[1].name.substr(-3) === "glb" || files[1].name.substr(-3) === "fbx") {
          props.addAvatarFiles(files[0],files[1]);
      } else {
        props.displayError("veuillez sélectionner un fichier WT3 et GLB ou FBX");
      }
    } else if (files[1].name.substr(-3) === "wt3") {
      if(files[0].name.substr(-3) === "glb" || files[0].name.substr(-3) === "fbx") {
        props.addAvatarFiles(files[1],files[0]);
      } else {
        props.displayError("veuillez sélectionner un fichier WT3 et GLB ou FBX");
      }
    } else {
      props.displayError("veuillez sélectionner un fichier WT3 et GLB ou FBX");
    }
  }

  function submitForm(e) {
    e.preventDefault();
    if(props.working || props.uploading) return;
    props.saveTarget();
  }

  const is3D = props.targetType === "3D";

  return (
    <Dialog
     open={props.editingTarget}
     onClose={handleClose}
     scroll="body"
     aria-labelledby="form-dialog-title"
    >
       <DialogTitle id="form-dialog-title">Nouvelle cible</DialogTitle>
       <form onSubmit={submitForm}>
        <DialogContent>
        <FormControl component="fieldset" className={props.classes.targetTypeButtons}>
          <RadioGroup row aria-label="targetType" name="targetType" value={props.targetType} onChange={(e) => {props.changeTargetType(e.target.value)}}>
           <FormControlLabel
           className={props.classes.targetTypeRadioButton}
             value="2D"
             control={<Radio color="primary" />}
             label="2D"
             labelPlacement="top"
           />
           <FormControlLabel
           className={props.classes.targetTypeRadioButton}
             value="3D"
             control={<Radio color="primary" />}
             label="3D"
             labelPlacement="top"
           />
           </RadioGroup>
         </FormControl>
         <DialogContentText>
         {is3D ?
           `Une cible 3D doit pointer vers une animation contenue dans un fichier 3D de type wt3 et vers un fichier glb ou fbx. Une cible 3D
           peut ne pas référencer de vidéos : dans ce cas l'appui sur l'animation par l'utilisateur ouvrira
           l'interface avatar. Il est préférable de regrouper plusieurs animations dans un fichier wt3 et utiliser ce même fichir dans plusieurs cibles.` :
            `Une cible 2D peut référencer une ou plusieurs vidéos et doit forcement définir
            une animation sous la forme d'une sprite sheet. Si une cible référence plusieurs vidéos,
            une liste déroulante sera proposée à l'utilisateur (à la manière du lexique de Viggo).`
          }
         </DialogContentText>
         <FormControl margin="normal" required fullWidth>
         {is3D ?
           <Card align="center" className={props.classes.paper2}>
           <Grid container spacing={16}>
             <Grid item xs={6}>
               <FormControl className={props.classes.formControl} required fullWidth>
                  <InputLabel id="avatar-file-select-label">Avatar</InputLabel>
                  <Select
                    id="avatar-file-select"
                    className={props.classes.avatarSelect}
                    value={(props.targetAvatarFile && props.targetAvatarFile.id) || ""}
                    onChange={selectAvatarFile}
                    disabled={props.working}
                    required
                  >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                    {props.avatarFiles.map((a) => <MenuItem value={a.id}>{a.get("name")}</MenuItem>)}
                  </Select>
                  <FormHelperText>Utilisez un avatar existant</FormHelperText>
                  <TextField
                    style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                    id="avatarFileReqFieldHidden"
                    value={(props.targetAvatarFile && props.targetAvatarFile.id) || ""}
                    type="text"
                    required
                   />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              {props.uploading ?
                <CircularProgress className={props.classes.progress} />
                :
                 <Button onClick={forwardAddAvatarClick}>
                   <AddFileIcon className={props.classes.bigIcon} color="action"/>
                   <Input
                     id="avatarFile"
                     name="avatarFile"
                     autoComplete="avatarFile"
                     type="file"
                     required={false}
                     inputProps={
                       {
                         accept: ".wt3,.glb,.fbx",
                         multiple: true
                       }
                     }
                     style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                     onChange={(e) => addAvatarFiles(e.target.files)}
                   />
                   <FormHelperText>Ajouter un avatar</FormHelperText>
                 </Button>
               }
              </Grid>
             </Grid>
           </Card>
          :
           <Card align="center" className={props.targetSpriteSheet ? null : props.classes.paper2}>
           {props.uploading ? <CircularProgress className={props.classes.progress} /> :
           props.targetSpriteSheet ?
             <CardActionArea onClick={forwardImageClick}>
               <CardMedia
                 className={props.classes.cardMedia}
                 component="img"
                 image={props.targetSpriteSheet.replace("http://","https://")} // eslint-disable-line max-len
               />
               <Input
                 id="spriteSheet"
                 name="spriteSheet"
                 autoComplete="spriteSheet"
                 type="file"
                 inputProps={
                   {
                     accept: "image/jpeg, image/png"
                   }
                 }
                 style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                 onChange={(e) => props.changeTargetSpriteSheet(e.target.files[0])}
                 required={false}
               />
             </CardActionArea>
             :
             <>
               <Button onClick={forwardImageClick}>
                 <AddPhotoIcon className={props.classes.bigIcon} color="action"/>
                 <Input
                   id="spriteSheet"
                   name="spriteSheet"
                   autoComplete="spriteSheet"
                   type="file"
                   inputProps={
                     {
                       accept: "image/jpeg, image/png"
                     }
                   }
                   style={{position:"absolute",width: 0, height: 0,margin:0,padding:0}}
                   onChange={(e) => props.changeTargetSpriteSheet(e.target.files[0])}
                   required
                 />
               </Button>
               <Typography variant="subtitle1">Veuillez télédéposer la sprite sheet</Typography>
             </>
           }
           </Card>
         }
         </FormControl>

         {
           is3D ?
            <>
             <FormControl margin="normal" required fullWidth>
             <TextField
               value={props.targetAvatarAnimationId || ""}
               fullWidth
               onChange={(e) => {props.changeTargetAvatarAnimationId(e.target.value)}}
               id="avatarAnimationId"
               label="Id de l'animation"
               type="text"
               required
               disabled={props.working}
              />
              </FormControl>
            </>
            :
            <Grid container spacing={16}>
              <Grid item xs={4}>
              <FormControl margin="normal" required fullWidth>
               <TextField
                 value={props.targetSpriteSheetColumns || 1}
                 onChange={(e) => {props.changeTargetSpriteSheetColumns(e.target.value ? Math.abs(e.target.value) : 1)}}
                 id="nbColumns"
                 label="Colonnes"
                 type="number"
                 disabled={props.working}
                />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
              <FormControl margin="normal" required fullWidth>
               <TextField
                 value={props.targetSpriteSheetNbSprites || 1}
                 onChange={(e) => {props.changeTargetSpriteSheetNbSprites(e.target.value ? Math.abs(e.target.value) : 1)}}
                 id="nbSprites"
                 label="Sprites"
                 type="number"
                 disabled={props.working}
                />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
             <FormControl margin="normal" required fullWidth>
              <TextField
                value={props.targetAnimationSpeed || 1}
                onChange={(e) => {props.changeTargetAnimationSpeed(e.target.value ? Math.abs(e.target.value) : 1)}}
                id="animationSpeed"
                label="Vitesse (i/s)"
                type="number"
                disabled={props.working}
               />
             </FormControl>
              </Grid>
            </Grid>
          }

         <Grid container spacing={16}>
           <Grid item xs={4}>
           <FormControl margin="normal" required fullWidth>
            <TextField
              value={props.targetXOffset || 0}
              onChange={(e) => {props.changeTargetXOffset(e.target.value ? e.target.value : 0)}}
              id="xOffset"
              label="Offset x"
              type="number"
              inputProps={{'step': 'any'}}
              disabled={props.working}
             />
             </FormControl>
           </Grid>
           <Grid item xs={4}>
           <FormControl margin="normal" required fullWidth>
            <TextField
              value={props.targetYOffset || 0}
              onChange={(e) => {props.changeTargetYOffset(e.target.value ? e.target.value : 0)}}
              id="yOffset"
              label="Offset y"
              type="number"
              inputProps={{'step': 'any'}}
              disabled={props.working}
             />
             </FormControl>
           </Grid>
           <Grid item xs={4}>
          <FormControl margin="normal" required fullWidth>
           <TextField
             value={props.targetZoom || 1}
             onChange={(e) => {props.changeTargetZoom(e.target.value ? e.target.value : 1)}}
             id="zoom"
             label="Zoom"
             type="number"
             inputProps={{'step': 'any'}}
             disabled={props.working}
            />
          </FormControl>
           </Grid>
         </Grid>

       <FormControl margin="normal" required fullWidth>
       <TextField
         value={props.targetWikitudeId || ""}
         fullWidth
         onChange={(e) => {props.changeTargetWikitudeId(e.target.value)}}
         id="wikitudeTargetId"
         label="Identifiant wikitude"
         type="text"
         required
         disabled={props.working}
        />
        </FormControl>

        <Grid container spacing={16}>
        {
          props.targetVideos.map((v,index,array) => {
            var firstLine = index === 0;

            return (
              <React.Fragment key={"targetVideo" + index.toString()}>
              <Grid item xs={3}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="targetVideoUri">URL vidéo</InputLabel>
                <Input
                  value={props.targetVideos[index].uri || ""}
                  onChange={(e) => {props.changeTargetVideoUri(index,e.target.value)}}
                  id="targetVideoUri"
                  label="URL vidéo"
                  type="url"
                  required
                  disabled={props.working}
                  startAdornment={
                      <InputAdornment position="start">
                        <LinkIcon />
                      </InputAdornment>
                  }
                />
              </FormControl>
              </Grid>
              <Grid item xs={3}>
              <FormControl margin="normal" fullWidth>
                <InputLabel htmlFor="targetVideoVimeoId">ID vimeo</InputLabel>
                <Input
                  value={props.targetVideos[index].vimeoId || ""}
                  onChange={(e) => {props.changeTargetVideoVimeoId(index,e.target.value)}}
                  id="targetVideoVimeoId"
                  label="ID vimeo"
                  type="text"
                  disabled={props.working}
                  startAdornment={
                      <InputAdornment position="start">
                        <OndemandVideo />
                      </InputAdornment>
                  }
                />
              </FormControl>
              </Grid>
              <Grid item xs={2}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="targetVideoName">Nom</InputLabel>
                <Input
                  value={props.targetVideos[index].name || ""}
                  onChange={(e) => {props.changeTargetVideoName(index,e.target.value)}}
                  id="targetVideoName"
                  label="Nom de la vidéo"
                  type="text"
                  required
                  disabled={props.working}
                  startAdornment={
                      <InputAdornment position="start">
                        <TitleIcon />
                      </InputAdornment>
                  }
                />
              </FormControl>
              </Grid>
              <Grid item xs={2}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="targetVideoColor">Couleur</InputLabel>
                <Input
                  value={props.targetVideos[index].color || "#F78E4A"}
                  onChange={(e) => {props.changeTargetVideoColor(index,e.target.value)}}
                  id="targetVideoColor"
                  label="Couleur"
                  type="text"
                  required
                  disabled={props.working}
                  startAdornment={
                      <InputAdornment position="start">
                        <ColorIcon />
                      </InputAdornment>
                  }
                />
              </FormControl>
              </Grid>
              {!firstLine || is3D ?
                <Grid item xs={2}>
                <FormControl margin="normal" required fullWidth style={{height: "100%"}}>
                  <Button
                    style={{margin: "auto"}}
                    onClick={() => {props.removeTargetVideo(index)}}
                    disabled={props.working}
                    >
                    <ClearIcon className={props.classes.littleIcon} color="action"/>
                  </Button>
                </FormControl>
                </Grid>
                : ""
              }
              </React.Fragment>
            );
          })
        }
        <Grid item xs={12}>
        <FormControl margin="normal" required>
          <Button
            color="default"
            onClick={props.addTargetVideo}
            disabled={props.working}
            variant="contained"
          >
            Ajouter une vidéo
          </Button>
        </FormControl>
        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            disabled={props.working}
          >
            Annuler
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={props.uploading}
          >
            {props.working ? <CircularProgress color="white" size={20} />
              : "Sauvegarder"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

TargetEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TargetEdit);
