import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Link
} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AdsIcon from '@material-ui/icons/Loyalty';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import IphoneIcon from '@material-ui/icons/PhoneIphone';
import AndroidIcon from '@material-ui/icons/Android';
import WatchIcon from '@material-ui/icons/Camera';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  customRouterLink: {
    display: "inherit",
    textDecoration: "none"
  }
};

function DenseAppBar(props) {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={() => {props.switchDrawer(true)}}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            {props.title}
          </Typography>
          {
            props.user ?
              <Button
                color="inherit"
                onClick={props.logout}
              >
                Logout
              </Button>
            :
              null
          }
        </Toolbar>
      </AppBar>
      <Drawer
        open={props.showingDrawer}
        onClose={() => {props.switchDrawer(false)}}
        variant="temporary">
        { props.user ?
        <List>
          <ListItem button>
            <Link to="/" className={props.classes.customRouterLink} onClick={() => {props.backHome();props.switchDrawer(false);}}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText>
              Catalogue
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem button>
            <Link to="/ads" className={props.classes.customRouterLink} onClick={() => {props.adsCatalog();props.switchDrawer(false);}}>
              <ListItemIcon>
                <AdsIcon />
              </ListItemIcon>
              <ListItemText>
              Publicités
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem button>
            <Link to="/freeAccesses" className={props.classes.customRouterLink} onClick={() => {props.freeAccesses();props.switchDrawer(false);}}>
              <ListItemIcon>
                <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText>
              Comptes presse
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem button>
            <Link to="/user-list" className={props.classes.customRouterLink} onClick={() => {props.freeAccesses();props.switchDrawer(false);}}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText>
              Utilisateurs
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem button>
            <Link  to="/" className={props.classes.customRouterLink} onClick={() => {window.open(props.firebasePushNotificationsLink);props.switchDrawer(false);}}>
              <ListItemIcon>
                <NotificationsActiveIcon />
              </ListItemIcon>
              <ListItemText>
              Push notifications
              </ListItemText>
            </Link>
          </ListItem>
          <ListItem button onClick={() => {props.logout();props.switchDrawer(false);}}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>
            Logout
            </ListItemText>
          </ListItem>
          <ListItem>
          <ListItemText>
          <Divider />
          </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.wikitudeLink)}}>
            <ListItemIcon>
              <WatchIcon />
            </ListItemIcon>
            <ListItemText>
            Console Wikitude
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.googleConsoleLink)}}>
            <ListItemIcon>
              <AndroidIcon />
            </ListItemIcon>
            <ListItemText>
            Console Google
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.appleConsoleLink)}}>
            <ListItemIcon>
              <IphoneIcon />
            </ListItemIcon>
            <ListItemText>
            Console Apple
            </ListItemText>
          </ListItem>
        </List>
        :
        <List>
          <ListItem button onClick={() => {props.backHome();props.switchDrawer(false);}}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>
            Login
            </ListItemText>
          </ListItem>
          <ListItem>
          <ListItemText>
          <Divider />
          </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.wikitudeLink)}}>
            <ListItemIcon>
              <WatchIcon />
            </ListItemIcon>
            <ListItemText>
            Console Wikitude
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.googleConsoleLink)}}>
            <ListItemIcon>
              <AndroidIcon />
            </ListItemIcon>
            <ListItemText>
            Console Google
            </ListItemText>
          </ListItem>
          <ListItem button onClick={() => {window.open(props.appleConsoleLink)}}>
            <ListItemIcon>
              <IphoneIcon />
            </ListItemIcon>
            <ListItemText>
            Console Apple
            </ListItemText>
          </ListItem>
        </List>
        }
      </Drawer>
    </div>
  );
}

DenseAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DenseAppBar);
