import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import AddPhotoIcon from '@material-ui/icons/AddAPhoto';
import IphoneIcon from '@material-ui/icons/PhoneIphone';
import AndroidIcon from '@material-ui/icons/Android';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActionArea from '@material-ui/core/CardActionArea';
import TargetEdit from './TargetEdit.js'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  appBar: {
    position: 'relative',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  bigIcon: {
    margin: theme.spacing.unit,
    fontSize: 64,
  },
  bigPlusIcon: {
    position: "absolute",
    top: "calc(50% - 32px)",
    left: "calc(50% - 32px)",
    fontSize: 64,
  },
  heroUnit: {
    backgroundColor: theme.palette.background.paper,
  },
  heroContent: {
    maxWidth: 800,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
  },
  heroButtons: {
    marginTop: theme.spacing.unit * 4,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
      width: 1100,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardGrid: {
    padding: `${theme.spacing.unit * 8}px 0`,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardTargetAdd : {
    paddingTop: '50%', // 16:9
  },
  cardMedia: {
    paddingTop: '100%', // 16:9
  },
  squareCard: {
    width: '100%',
    paddingTop: '100%', // 16:9
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 6,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  paper: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  paper2: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  littleProgress: {
    width:'auto',
    height: 'auto',
  }
});

function ConfirmationScreen(props,isNew) {
  return (
    <div className={classNames(props.classes.layout, props.classes.cardGrid)} align="center">
      <Grid item xs={6}>
        {props.working ?
          <CircularProgress className={props.classes.progress} /> :
          <>
            <Typography variant="h5">
              Le livre "{props.title}" a été {isNew ? "créé" : "édité"} avec succès.
            </Typography>
            {isNew ?
              <Typography variant="body1"><br/>
                Ce livre a bien été ajouté à la base de donnée.<br/>
                Par mesure de sécurité, seul les comptes administrateurs peuvent actuellement le consulter
                dans l'application. Afin de rendre public un livre, cliquez sur ce dernier dans le catalogue puis cliquez sur "publier".<br/>
                <br/>Notez qu'un livre ayant été acheté par un utilisateur ou plus ne peut pas être supprimé. Il est possible de le rendre invisible aux autre en cliquant sur "rendre privé".
              </Typography> :
              <Typography variant="body1"><br/>
              Ce livre a bien été modifié dans la base de donnée, les changements sont visibles dans l'application.
              </Typography>
            }
          </>
        }
      </Grid>
    </div>
  );
}

function AddTargetButton(props) {
  return (
    <Grid item key="newTarget" sm={4} md={3} lg={2}>
      <CardActionArea onClick={props.createTarget}>
        <Card className={props.classes.squareCard} align="center">
              <AddCircleIcon className={props.classes.bigPlusIcon} color="action"/>
        </Card>
      </CardActionArea>
      <TargetEdit
        editingTarget={props.editingTarget}
        discardTarget={props.discardTarget}
        saveTarget={props.saveTarget}
        targetType={props.targetType}
        targetWikitudeId={props.targetWikitudeId}
        targetSpriteSheet={props.targetSpriteSheet}
        targetSpriteSheetNbSprites={props.targetSpriteSheetNbSprites}
        targetSpriteSheetColumns={props.targetSpriteSheetColumns}
        targetAnimationSpeed={props.targetAnimationSpeed}
        targetXOffset={props.targetXOffset}
        targetYOffset={props.targetYOffset}
        targetZoom={props.targetZoom}
        changeTargetType={props.changeTargetType}
        changeTargetWikitudeId={props.changeTargetWikitudeId}
        changeTargetSpriteSheet={props.changeTargetSpriteSheet}
        changeTargetSpriteSheetNbSprites={props.changeTargetSpriteSheetNbSprites}
        changeTargetSpriteSheetColumns={props.changeTargetSpriteSheetColumns}
        changeTargetAnimationSpeed={props.changeTargetAnimationSpeed}
        changeTargetXOffset={props.changeTargetXOffset}
        changeTargetYOffset={props.changeTargetYOffset}
        changeTargetZoom={props.changeTargetZoom}

        targetVideos={props.targetVideos}
        changeTargetVideoName={props.changeTargetVideoName}
        changeTargetVideoUri={props.changeTargetVideoUri}
        changeTargetVideoVimeoId={props.changeTargetVideoVimeoId}
        changeTargetVideoColor={props.changeTargetVideoColor}
        addTargetVideo={props.addTargetVideo}
        removeTargetVideo={props.removeTargetVideo}

        avatarFiles={props.avatarFiles}
        targetAvatarFile={props.targetAvatarFile}
        targetAvatarAnimationId={props.targetAvatarAnimationId}
        addAvatarFiles={props.addAvatarFiles}
        changeTargetAvatarFile={props.changeTargetAvatarFile}
        changeTargetAvatarAnimationId={props.changeTargetAvatarAnimationId}

        working={props.working}
        uploading={props.uploading}
        displayError={props.displayError}
      />
    </Grid>
  );
}

function Target(props) {
  function LaunchTargetMenu() {
    props.showTargetMenu(props.target);
  }

  function HandleClose() {
    props.hideTargetMenu();
  }

  function Edit() {
    props.editTarget(props.target);
  }

  function Delete() {
    props.removeSelectedTarget();
  }

  return (
    <Grid item key={props.target.id} sm={4} md={3} lg={2}>
    <CardActionArea
    id={"target_" + props.target.id}
    onClick={LaunchTargetMenu}
    aria-owns={props.selected ? 'simple-menu' : undefined}
    aria-haspopup="true">
      <Card className={props.classes.card}>
        <CardContent>
          <Typography color="textSecondary">
            {props.target.get("wikitudeId")}
          </Typography>
        </CardContent>
        <CardMedia
          className={props.classes.cardMedia}
          image={props.target.get("spriteSheet")?.url().replace("http://","https://")} // eslint-disable-line max-len
          title={props.target.get("wikitudeId")}
        />
      </Card>
    </CardActionArea>
    <Menu
      id="simple-menu"
      open={props.selected}
      onClose={HandleClose}
      anchorEl={document.getElementById("target_" + props.target.id)}
    >
      <MenuItem onClick={Edit}>Éditer</MenuItem>
      <MenuItem onClick={Delete}>Supprimer</MenuItem>
    </Menu>
    </Grid>
  );
}

function BookARContentEdit(props,handleNext) {
  const targets = props.targets.map((target,index) => {
    var selectedTarget = props.selectedTarget ? target.id ===  props.selectedTarget.id : false;
    return (<Target
      target={target}
      key={index}
      classes={props.classes}
      showTargetMenu={props.showTargetMenu}
      hideTargetMenu={props.hideTargetMenu}
      selected={selectedTarget}
      editTarget={props.editTarget}
      removeSelectedTarget={props.removeSelectedTarget}
      />)
  });

  targets.push(AddTargetButton(props));

  function checkContent() {
    if(props.targets.length === 0) {
      props.displayError("Il est nécessaire d'ajouter au moins une cible");
      return;
    }

    handleNext();
  }

  function switchIsBookDownloadable() {
    props.switchIsBookDownloadable(!props.isBookDownloadable);
  }

  return (
    <>
    <form className={props.classes.form} onSubmit={(e) => {e.preventDefault();checkContent();}}>
      <Button id="step3Submit" type="submit" style={{display: "none"}}>-</Button>
      <Grid item xs={6}>
      <Card className={props.classes.paper2}>
        <FormControl margin="normal" required fullWidth>
         <TextField
           value={props.nbSimultaneousTargets || 1}
           onChange={(e) => {props.changeNbSimultaneousTargets(e.target.value && e.target.value !== 0 ? Math.abs(e.target.value) : 1)}}
           id="nbSimultaneousTargets"
           label="Nombre de cibles simultanées (affecte la performance si plus qu'une)"
           type="number"
           disabled={props.working}
          />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <FormControlLabel
            control={
              <Switch
                checked={props.isBookDownloadable}
                onChange={switchIsBookDownloadable}
                color="primary"
              />
            }
            label={props.isBookDownloadable ? "Lecture hors-ligne activée" : "Lecture en ligne uniquement"}
          />
        </FormControl>
        </Card>
      </Grid>
      </form>
    <div className={classNames(props.classes.layout, props.classes.cardGrid)} align="center">
      <Grid container spacing={40}>
        {targets}
      </Grid>
    </div>
    </>
  );
}

function BookARDbEdit(props, handleNext) {
  function forwardFileClick() {
    var fileInput = document.querySelector("#wtcfile");
    fileInput.click();
  }

  return(
    <form className={props.classes.form} onSubmit={(e) => {e.preventDefault();handleNext();}}>
      <Button id="step2Submit" type="submit" style={{display: "none"}}>-</Button>
      <Grid item xs={6}>
        <FormControl margin="normal" required fullWidth>
          <Card className={props.classes.paper2}>
          {props.uploading ? <CircularProgress className={props.classes.progress} /> :
          props.wtcFile ?
            <>
              <Button onClick={forwardFileClick}>
                <CloudDoneIcon className={props.classes.bigIcon} color="primary"/>
                <EditIcon color="action" />
                <Input
                  id="wtcfile"
                  name="wtcfile"
                  autoComplete="wtcfile"
                  type="file"
                  inputProps={
                    {
                      accept: ".wtc"
                    }
                  }
                  style={{position: "absolute", width:0,padding:0,margin:0,height:0}}
                  onChange={(e) => props.changeWtcFile(e.target.files[0])}
                  required={false}
                />
              </Button>
              <Typography variant="subtitle1">Fichier WTC télédéposé</Typography>
            </>
            :
            <>
              <Button onClick={forwardFileClick}>
              <CloudUploadIcon className={props.classes.bigIcon} color="action"/>
              <Input
                id="wtcfile"
                name="wtcfile"
                autoComplete="wtcfile"
                type="file"
                inputProps={
                  {
                    accept: ".wtc"
                  }
                }
                style={{position: "absolute", width:0,padding:0,margin:0,height:0}}
                onChange={(e) => props.changeWtcFile(e.target.files[0])}
                required
              />
              </Button>
              <Typography variant="subtitle1">Veuillez télédéposer le fichier WTC</Typography>
            </>
          }
            <Typography variant="body1" align="center" color="textSecondary" paragraph>
              À chaque livre doit être associé un fichier WTC unique, généré sur
              l'interface Wikitude.
            </Typography>
            <div className={props.classes.heroButtons}>
              <Grid container spacing={16} justify="center">
                <Grid item>
                  <Button variant="outlined" color="primary" onClick={() => {window.open(props.wikitudeLink)}}>
                    Gérer les projets Wikitude
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </FormControl>
      </Grid>
    </form>
  );
}

function BookListingEdit(props,handleNext) {
  return(
    <>
    <Grid item xs={6}>
      <Card className={props.classes.paper}>
        <Typography variant="h6" align="center" color="textPrimary">Listing produit Google Play/Apple</Typography>
        <Typography variant="body1" align="center" color="textSecondary" paragraph>
          À chaque livre doit être associé un produit sur Google Play et un produit
          sur l'App store d'Apple. Les prix, les devises, etc. sont gérées directement
          sur le site de ces partenaires.
        </Typography>
        <div className={props.classes.heroButtons}>
          <Grid container spacing={16} justify="center">
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.appleProductsLink)}}>
                Gérer les produits Apple
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary" onClick={() => {window.open(props.googleProductsLink)}}>
                Gérer les produits Google
              </Button>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Grid>
    <Grid item xs={6}>
      <form className={props.classes.form} onSubmit={(e) => {e.preventDefault();handleNext();}}>
        <Button id="step1Submit" type="submit" style={{display: "none"}}>-</Button>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="appleProductId">Identifiant produit Apple (si vide, gratuit)</InputLabel>
          <Input
            id="appleProductId"
            name="appleProductId"
            autoComplete="appleProductId"
            type="text"
            value={props.appleProductId || ""}
            onChange={(e) => props.changeAppleProductId(e.target.value)}
            startAdornment={
                <InputAdornment position="start">
                  <IphoneIcon />
                </InputAdornment>
            }
            />
        </FormControl>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="googleProductId">Identifiant produit Google (si vide, gratuit)</InputLabel>
          <Input
            id="googleProductId"
            name="googleProductId"
            autoComplete="googleProductId"
            type="text"
            value={props.googleProductId || ""}
            onChange={(e) => props.changeGoogleProductId(e.target.value)}
            startAdornment={
                <InputAdornment position="start">
                  <AndroidIcon />
                </InputAdornment>
            }
            />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="partnerLinkUrl">Lien d'achat du livre papier / site partenaire</InputLabel>
          <Input
            id="partnerLinkUrl"
            name="partnerLinkUrl"
            autoComplete="partnerLinkUrl"
            type="url"
            value={props.partnerLinkUrl || ""}
            onChange={(e) => props.changePartnerLinkUrl(e.target.value)}
            required
            startAdornment={
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
            }
            />
        </FormControl>
      </form>
    </Grid>
    </>);
}

function BookDescriptionEdit(props,handleNext) {
  function forwardImageClick() {
    var coverInput = document.querySelector("#cover");
    coverInput.click();
  }

  function forwardHeaderImageClick() {
    var headerInput = document.querySelector("#headerImage");
    headerInput.click();
  }

  const idToLanguage = ["fr", "en", "it"];

  return (
    <form className={props.classes.form} onSubmit={(e) => {e.preventDefault();handleNext();}}>
      <Button id="step0Submit" type="submit" style={{display: "none"}}>-</Button>
      <Grid item xs={3}>
        <FormControl margin="normal" required fullWidth>
          <Card className={props.cover ? null : props.classes.paper2}>
          {props.uploading ? <CircularProgress className={props.classes.progress} /> :
          props.cover ?
            <CardActionArea onClick={forwardImageClick}>
              <CardMedia
                id="coverCard"
                style={{paddingTop: "150%"}}
                image={props.cover} // eslint-disable-line max-len
                title={props.title}
              />
              <Input
                id="cover"
                name="cover"
                autoComplete="cover"
                type="file"
                inputProps={
                  {
                    accept: "image/jpeg, image/png"
                  }
                }
                style={{position:"absolute",width:0,height:0,padding:0,margin:0}}
                onChange={(e) => props.changeCover(e.target.files[0])}
                required={false}
              />
            </CardActionArea>
            :
            <>
              <Button onClick={forwardImageClick}>
                <AddPhotoIcon className={props.classes.bigIcon} color="action"/>
                <Input
                  id="cover"
                  name="cover"
                  autoComplete="cover"
                  type="file"
                  inputProps={
                    {
                      accept: "image/jpeg, image/png"
                    }
                  }
                  style={{position:"absolute",width:0,height:0,padding:0,margin:0}}
                  onChange={(e) => props.changeCover(e.target.files[0])}
                  required
                />
              </Button>
              <Typography variant="subtitle1">Veuillez télédéposer l'image de couverture</Typography>
              <Typography variant="subtitle2">format recommandé: 512x768</Typography>
            </>
          }
          </Card>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl margin="normal" required fullWidth>
          <Card className={props.headerImage ? null : props.classes.paper2}>
          {props.uploading ? <CircularProgress className={props.classes.progress} /> :
          props.headerImage ?
            <CardActionArea onClick={forwardHeaderImageClick}>
              <CardMedia
                id="headerImageCard"
                style={{paddingTop: "64%"}}
                image={props.headerImage} // eslint-disable-line max-len
                title={props.title}
              />
              <Input
                id="headerImage"
                name="headerImage"
                autoComplete="headerImage"
                type="file"
                inputProps={
                  {
                    accept: "image/jpeg, image/png"
                  }
                }
                style={{position:"absolute",width:0,height:0,padding:0,margin:0}}
                onChange={(e) => props.changeHeaderImage(e.target.files[0])}
                required={false}
              />
            </CardActionArea>
            :
            <>
              <Button onClick={forwardHeaderImageClick}>
                <AddPhotoIcon className={props.classes.bigIcon} color="action"/>
                <Input
                  id="headerImage"
                  name="headerImage"
                  autoComplete="headerImage"
                  type="file"
                  inputProps={
                    {
                      accept: "image/jpeg, image/png"
                    }
                  }
                  style={{position:"absolute",width:0,height:0,padding:0,margin:0}}
                  onChange={(e) => props.changeHeaderImage(e.target.files[0])}
                  required
                />
              </Button>
              <Typography variant="subtitle1">Veuillez télédéposer l'image d'entête</Typography>
              <Typography variant="subtitle2">format recommandé: 750x480</Typography>
            </>
          }
          </Card>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
      <FormControl margin="normal" fullWidth>
        <InputLabel htmlFor="sampleVideo">Extrait vidéo (facultatif)</InputLabel>
        <Input
          id="sampleVideo"
          name="sampleVideo"
          autoComplete="sampleVideo"
          type="url"
          value={props.sampleVideo || ""}
          onChange={(e) => props.changeSampleVideo(e.target.value)}
          startAdornment={
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
          }
          />
      </FormControl>
      <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="language">Langage</InputLabel>
          <Select
            value={idToLanguage.indexOf(props.language || "fr")}
            name="langage"
            onChange={(e) => props.changeLanguage(idToLanguage[e.target.value])}
            input={<Input id="language" align="left"/>}
          >
            <MenuItem value={0}>français</MenuItem>
            <MenuItem value={1}>anglais</MenuItem>
            <MenuItem value={2}>italien</MenuItem>
          </Select>
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="title">Titre</InputLabel>
          <Input
            id="title"
            name="title"
            autoComplete="title"
            type="text"
            value={props.title || ""}
            onChange={(e) => props.changeTitle(e.target.value)}
            required
            />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="author">Auteur</InputLabel>
          <Input
            id="auhtor"
            name="author"
            autoComplete="author"
            type="text"
            value={props.author || ""}
            onChange={(e) => props.changeAuthor(e.target.value)}
            required
            />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="editor">Editeur</InputLabel>
          <Input
            id="editor"
            name="editor"
            autoComplete="editor"
            type="text"
            value={props.editor || ""}
            onChange={(e) => props.changeEditor(e.target.value)}
            required
            />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
            <TextField
              required
              id="short-description"
              label="Description courte"
              autoComplete="short-description"
              multiline
              value={props.shortDescription || ""}
              onChange={(e) => props.changeShortDescription(e.target.value)}
              helperText="Les quelques lignes présentes dans le catalogue"
              rows="2"
            />
        </FormControl>
        <FormControl margin="normal" required fullWidth>
            <TextField
              required
              id="description"
              label="Description"
              autoComplete="descrption"
              multiline
              value={props.description || ""}
              onChange={(e) => props.changeDescription(e.target.value)}
              helperText="La description présente sur la fiche produit"
              rows="5"
            />
        </FormControl>
      </Grid>
    </form>
  );
}

// Container Component
function BookEdit(props) {
  const { classes } = props;

  const steps = ['Description du livre', 'Prix et listing', 'Fichier wikitude', 'Cibles et vidéos'];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return BookDescriptionEdit(props,handleNext);
      case 1:
        return BookListingEdit(props,handleNext);
      case 2:
        return BookARDbEdit(props,handleNext);
      case 3:
        return BookARContentEdit(props,handleNext);
      case 4:
        return ConfirmationScreen(props, props.editedBookIsNew);
      default:
        return 'Unknown step';
    }
  }

  const handleBack = () => {
    if(props.activeStep === 0) {
      props.finishBookEdit();
      return;
    }

    props.changeStep(props.activeStep-1);
  }

  const checkForm = () => {
    var id = "#step" + props.activeStep + "Submit";
    document.querySelector(id).click();
  }

  const handleNext = () => {
    if(props.activeStep === steps.length - 1) props.saveBook();
    props.changeStep(props.activeStep+1);
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              {props.title || "Nouveau Livre"}
            </Typography>
            <Stepper activeStep={props.activeStep}>
              {steps.map((label, index) => {
                const props = {};
                const labelProps = {};
                return (
                  <Step key={label} {...props}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        </div>
        <div className={classNames(classes.layout, classes.cardGrid)} align="center">
          {/* End hero unit */}
          {getStepContent(props.activeStep)}
        </div>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <div align="center">
          {props.activeStep === steps.length ? (
            <div>
              <Button
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={props.finishBookEdit}
                disabled={props.working || props.uploading}
                >
                Retour au catalogue
              </Button>
            </div>
          ) : (
            <div>
              <div>
                <Button
                  onClick={handleBack}
                  className={classes.button}
                >
                  {props.activeStep === 0 ? "Annuler" : "Étape précédente"}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={checkForm}
                  className={classes.button}
                  disabled={props.uploading}
                >
                  {props.activeStep === steps.length - 1 ?  'Sauvegarder'
                    : 'Étape suivante'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}

BookEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookEdit);
